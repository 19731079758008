import React, { useState } from 'react';

const TableList = ({ tables, addTable, onSelectTable }) => {
  const [newTableName, setNewTableName] = useState('');

  const handleAddTable = () => {
    if (newTableName) {
      addTable(newTableName);
      setNewTableName('');
    }
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-2">Tables</h2>
      <ul className="mb-4">
        {tables.map(table => (
          <li 
            key={table.name} 
            className="cursor-pointer hover:bg-gray-100 p-2"
            onClick={() => onSelectTable(table)}
          >
            {table.name}
          </li>
        ))}
      </ul>
      <input
        type="text"
        value={newTableName}
        onChange={(e) => setNewTableName(e.target.value)}
        className="border p-2 mr-2"
        placeholder="New table name"
      />
      <button 
        onClick={handleAddTable}
        className="bg-blue-500 text-white px-4 py-2 rounded"
      >
        Add Table
      </button>
    </div>
  );
};

export default TableList;