import React, { useState } from 'react';

const TableEditor = ({ table, updateTable }) => {
  const [newColumnName, setNewColumnName] = useState('');
  const [newRowData, setNewRowData] = useState({});

  const addColumn = () => {
    if (newColumnName && !table.columns.some(col => col.name === newColumnName)) {
      const updatedTable = {
        ...table,
        columns: [...table.columns, { name: newColumnName, formula: '' }],
        rows: table.rows.map(row => ({ ...row, [newColumnName]: '' }))
      };
      updateTable(updatedTable);
      setNewColumnName('');
    }
  };

  const addRow = () => {
    const newRow = table.columns.reduce((acc, col) => {
      acc[col.name] = newRowData[col.name] || '';
      return acc;
    }, {});
    
    const updatedTable = {
      ...table,
      rows: [...table.rows, newRow]
    };
    updateTable(updatedTable);
    setNewRowData({});
  };

  const updateCell = (rowIndex, columnName, value) => {
    const updatedRows = table.rows.map((row, index) => 
      index === rowIndex ? { ...row, [columnName]: value } : row
    );
    updateTable({ ...table, rows: updatedRows });
  };

  const updateColumnProperty = (columnName, property, value) => {
    const updatedColumns = table.columns.map(col => 
      col.name === columnName ? { ...col, [property]: value } : col
    );
    updateTable({ ...table, columns: updatedColumns });
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">{table.name}</h2>
      
      <div className="mb-4">
        <input
          type="text"
          value={newColumnName}
          onChange={(e) => setNewColumnName(e.target.value)}
          className="border p-2 mr-2"
          placeholder="New column name"
        />
        <button 
          onClick={addColumn}
          className="bg-green-500 text-white px-4 py-2 rounded"
        >
          Add Column
        </button>
      </div>

      <table className="w-full border-collapse border">
        <thead>
          <tr>
            {table.columns.map(column => (
              <th key={column.name} className="border p-2">
                {column.name}
                <input
                  type="text"
                  value={column.formula || ''}
                  onChange={(e) => updateColumnProperty(column.name, 'formula', e.target.value)}
                  className="block w-full mt-1 border p-1"
                  placeholder="Formula"
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {table.rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {table.columns.map(column => (
                <td key={column.name} className="border p-2">
                  <input
                    type="text"
                    value={row[column.name]}
                    onChange={(e) => updateCell(rowIndex, column.name, e.target.value)}
                    className="w-full"
                  />
                </td>
              ))}
            </tr>
          ))}
          <tr>
            {table.columns.map(column => (
              <td key={column.name} className="border p-2">
                <input
                  type="text"
                  value={newRowData[column.name] || ''}
                  onChange={(e) => setNewRowData({ ...newRowData, [column.name]: e.target.value })}
                  className="w-full"
                  placeholder="New value"
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>

      <button 
        onClick={addRow}
        className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
      >
        Add Row
      </button>
    </div>
  );
};

export default TableEditor;