import React, { useState } from 'react';
import TableList from './components/TableList';
import TableEditor from './components/TableEditor';

const App = () => {
  const [tables, setTables] = useState([]);
  const [selectedTableName, setSelectedTableName] = useState(null);

  const addTable = (tableName) => {
    setTables(prevTables => [...prevTables, { name: tableName, columns: [], rows: [] }]);
  };

  const updateTable = (updatedTable) => {
    setTables(prevTables => prevTables.map(table => 
      table.name === updatedTable.name ? updatedTable : table
    ));
  };

  const selectedTable = tables.find(table => table.name === selectedTableName);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Table Editor</h1>
      <div className="flex">
        <div className="w-1/3 pr-4">
          <TableList 
            tables={tables} 
            addTable={addTable} 
            onSelectTable={(table) => setSelectedTableName(table.name)}
          />
        </div>
        <div className="w-2/3">
          {selectedTable && (
            <TableEditor 
              table={selectedTable} 
              updateTable={updateTable} 
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default App;